jQuery(function ($) {
	jQuery('.slider').slick({
		speed: 1500,
		autoplaySpeed: 3000,
		arrows: true,
		dots: true,
		autoplay: true,
		infinite: true,
		pauseOnHover: false
	});
});
